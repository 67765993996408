const config = {
  rootProjectId: "root",
  uiBucketName: "isg.c.retter.io",
  appUrl: "https://api.isg.retter.io/",
  cosUrl: "api.isg.retter.io",
  version: "2.1.0",
  captchaKey: "6LdnqBEqAAAAAOyjfZ3UAkhE4FYk0OhQ3RVaCgAh",
  stage: "PROD"
}
export default config

